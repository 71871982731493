<template>
  <AuthenticationLayout title="" head-title="Login">
    <form @submit.prevent="submit" class="flex flex-col gap-10 pb-10">
      <TextInput
        id="email"
        v-model="form.email"
        type="email"
        :label="trans('login.email')"
        required
        autofocus
        :error="form.errors.email"
      />

      <div class="flex flex-col">
        <TextInput
          class="w-auto"
          id="password"
          :type="inputType"
          v-model:modelValue="form.password"
          :label="trans('login.password')"
          :error="form.errors.password"
          :icon="{
            name: iconName,
            size: 20,
            clickable: true,
            click: () => {
              isPasswordVisible = !isPasswordVisible;
            },
            position: 'right',
            color: '!text-primary-500',
          }"
        />

        <div class="flex justify-between mt-6">
          <InputCheckbox
            v-model="form.remember"
            name="remember"
            id="remember"
            :label="trans('login.remember_me')"
          />
        </div>
      </div>
      <PrimaryButton :label="trans('login.login')" :loading="form.processing"></PrimaryButton>
      <Link v-if="canResetPassword" :href="route('password.request')" class="text-center">
        {{ trans('login.forgot_password') }}</Link
      >
    </form>
  </AuthenticationLayout>
</template>

<script setup>
import { computed, ref } from 'vue';

import { useForm } from '@inertiajs/vue3';
import { trans } from 'laravel-vue-i18n';

import InputCheckbox from '@/Components/InputCheckbox.vue';
import Link from '@/Components/Link.vue';
import AuthenticationLayout from '@/Layouts/AuthenticationLayout.vue';
import TextInput from '@/Redesign/Components/Forms/TextInput.vue';
import PrimaryButton from '@/Redesign/Components/Buttons/PrimaryButton.vue';
import useAlert from '@/Redesign/composables/useAlert';

defineProps({
  canResetPassword: Boolean,
  status: String,
});

const form = useForm({
  email: '',
  password: '',
  remember: false,
});

const isPasswordVisible = ref(false);
const { setAlert } = useAlert();

const iconName = computed(() => (isPasswordVisible.value ? 'visibility_off' : 'visibility'));
const inputType = computed(() => (isPasswordVisible.value ? 'text' : 'password'));

const submit = () => {
  form
    .transform((data) => ({
      ...data,
      remember: form.remember ? 'on' : '',
    }))
    .post(route('login'), {
      onSuccess: () => {
        if (typeof window.setZohoUserInfo === 'function') {
          window.setZohoUserInfo();
        }
      },
      onFinish: () => {
        form.reset('password');
      },
      onError: (error) => {
        if (!error.email && !error.password) {
          setAlert('error', trans('login.coach.error'));
        }
      },
    });
};
</script>

<style scoped lang="scss">
/**
   *  Styles that can't be applied with Tailwind
   *  may be defined in this style block per component.
   **/
</style>
